import React, {useRef, useState} from "react"

import {Row, Col, Card, CardBody, Spinner} from "reactstrap"
import {Link} from "react-router-dom"

import avatar1 from "./../../../images/default.svg"
import PhoneNumber from "../Common/phoneNumber"
import Loader from "../Loader";

const ProfileData = ({
                         setProfileModal,
                         evaluation,
                         loading,
                         setNoResume,
                         uploadMediaToAWS,
                         addCandidatePdfResume,
                         history,
                         showAlert
                     }) => {
    const hiddenFileInput = useRef(null);

    const [loader, setLoader] = useState(false)

    const handleChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            console.log("File: ", file)
            const media_key =
                "user/" + file.lastModified + "." + file.type.split("/")[1];
            setLoader(true)
            uploadMediaToAWS({media_key: media_key, file: file}, history, (res) => {
                addCandidatePdfResume({interviewId: evaluation?.id, pdfResumeLink: media_key},
                    history, () => {
                        showAlert(true)
                        setLoader(false)
                    })

            });
        }
    };
    const submitRemovePDFResume = (e) => {
        e.preventDefault()
        setLoader(true)
        addCandidatePdfResume({interviewId: evaluation?.id, pdfResumeLink: null},
            history, () => {
                showAlert(true)
                setLoader(false)
            })
    }

    return (
        <React.Fragment>
            <div className="profile-data">
                <Card className="overflow-hidden" style={{height: "auto"}}>
                    <div className="bg-secondary top-section">
                        <div className="text-center text-white">
                            <h5 className="text-white">
                                {evaluation?.Candidate?.first_name} {evaluation?.Candidate?.last_name}
                            </h5>
                            {/*<p>{evaluation?.Candidate?.email}</p>*/}
                            <img
                                src={evaluation?.Candidate?.image || avatar1}
                                alt="user image"
                                className="img-thumbnail rounded-circle profile-image"
                            />
                        </div>
                    </div>

                    <CardBody className="">
                        <Row className="align-items-center">
                            <Col sm="">
                                <div className="">
                  <span className="text-subtitle mb-2">
                    <i className="bx bxs-graduation"></i> &nbsp;
                      {evaluation?.Position?.title}
                  </span>
                                    <span className="d-none mb-1">
                    <i className="bx bx-mail-send"></i>
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bx-phone-call"></i> &nbsp;
                                        <PhoneNumber number={evaluation?.Candidate?.phone}/>
                  </span>
                                    <span className="d-block mb-1">
                    <i className="bx bxs-envelope"></i> &nbsp;
                                        {evaluation?.Candidate?.email}
                  </span>
                                    {/* <button
                    className="d-block btn btn-outline-secondary mt-2"
                    onClick={() => setProfileModal(true)}
                  >
                    View Profile
                  </button> */}
                                    {evaluation?.Candidate?.CandidateProfile?.pdf_resume ? (
                                            <div className={"row"}>
                                                <a
                                                    className="d-block btn btn-outline-secondary mt-2 col-lg-5 m-2"
                                                    href={evaluation?.Candidate?.CandidateProfile?.pdf_resume ? evaluation?.Candidate?.CandidateProfile?.pdf_resume : ""}
                                                    target={`_blank`}

                                                >
                                                    PDF Resume
                                                </a>
                                                <a
                                                    className="d-block btn btn-outline-danger mt-2 col-lg-5 m-2"
                                                    href={""}
                                                    onClick={submitRemovePDFResume}
                                                    target={`_blank`}

                                                >
                                                    Remove
                                                </a>
                                            </div>
                                        ) :
                                        <div>
                                            <input
                                                type="file"
                                                className="custom-file-input d-block btn btn-outline-secondary mt-2"
                                                accept="application/pdf"
                                                ref={hiddenFileInput}
                                                onChange={(e) => handleChange(e)}
                                                style={{cursor: "pointer"}}
                                            />
                                            <a
                                                className="d-block btn btn-outline-secondary mt-2"
                                                style={{cursor: "pointer"}}
                                                // href={null}
                                                // style={{pointerEvents: "none"}}
                                                // target={`_blank`}

                                                onClick={() => hiddenFileInput.current.click()}
                                            >
                                                {loader ? <Loader spinnerClass="text-secondary"/> : "Upload Resume"}
                                            </a>

                                        </div>

                                    }

                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ProfileData
