import React, {useState, useLayoutEffect, useEffect} from "react"
import {Link, withRouter} from "react-router-dom"
import Multistep from "react-multistep"

import Logo from "./../components/Registration/Logo"
import Stepper from "./../components/Registration/Stepper"
import tabNames from "./../components/Registration/types"
import AccountSetup from "./../components/Registration/StepOne"
import Verification from "./../components/Registration/StepTwo"
import Profile from "./../components/Registration/StepThree"
import Complete from "./../components/Registration/StepFour"
import {hasCompanyProfile, isAuthenticated, isVerified} from "../../store/selectors/AuthSelectors"
import {connect} from "react-redux"
import LogoDark from "../../images/logo-dark.svg"
import LogoLight from "../../images/logo-light.svg"

const Register = (props) => {
    const history = props.history
    const [tab, setTab] = useState(() => {
        if (props?.history?.location?.account)
            return tabNames.ACCOUNT_SETUP;
        if (props.isAuthenticated) {
            if (!props.isVerified) {
                return tabNames.VERIFICATION

            } else if (!props.hasCompanyProfile) {

                return tabNames.PROFILE
            } else {
                history.push("/dashboard")
            }
        }
        return tabNames.ACCOUNT_SETUP
    })

    const tabSetter = (tab) => setTab(tab)

    return (

        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-xl-15 col-xxl-10">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title">
                                        <Link to="/dashboard">
                                            <img src={LogoDark} alt="" className="width140"/>
                                        </Link>
                                    </h4>
                                </div>
                                <Link className="ai-icon" to={"login"}>
                                    <i className="ti-home "></i>
                                </Link>
                            </div>
                            <div className="card-body">
                                {/* <Logo title={tab.name} /> */}
                                <Stepper tab={tab}/>
                                {tab === tabNames.ACCOUNT_SETUP ? (
                                    <AccountSetup
                                        onSubmit={(tabName) => tabName ? tabSetter(tabName) : tabSetter(tabNames.VERIFICATION)}
                                        history={history}/>
                                ) : (
                                    ""
                                )}
                                {tab === tabNames.VERIFICATION ? (
                                    <Verification onSubmit={() => tabSetter(tabNames.PROFILE)}/>
                                ) : (
                                    ""
                                )}
                                {tab === tabNames.PROFILE ? (
                                    <Profile onSubmit={() => tabSetter(tabNames.COMPLETE)}/>
                                ) : (
                                    ""
                                )}
                                {tab === tabNames.COMPLETE ? <Complete/> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
        isVerified: isVerified(state),
        hasCompanyProfile: hasCompanyProfile(state),

    }
}


export default withRouter(connect(mapStateToProps)(Register))
