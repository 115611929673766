import Alert from "../Common/Alert";
import {Col, Row, UncontrolledDropdown} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {
    addCompanyCandidateTeamMemberAction,
    addCompanyInterviewTagAction,
    addCompanyTagAction, deleteCompanyTagAction, getCompanyCandidateFilterDataAction
} from "../../../store/actions/CompanyActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    getDataForMembersModal,
    getDataForTagsModal,
    getMeiaAddedMessage
} from "../../../store/selectors/CompanySelector";

const TagsInput = (props) => {
    const {
        interview,
        tags,
        onHide,
        getMemberData,
        getTagsData,
        addTag,
        addInterviewTag,
        addTeamMemberAction,
        getMessage,
        deleteCompanyTag,
        renderInterviewTags,
        getCompanyFilterData
    } = props
    let errorsObj = {tag: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [tag, setTag] = useState("")
    const [color, setColor] = useState("#556ee6")
    const [dropdown, setDropdown] = useState(false)
    const [colorMenu, setColorMenu] = useState(false)
    const [alert, showAlert] = useState(false)

    const ref = useRef()
    const colorMenuRef = useRef()
    useOnClickOutside(ref, () => setDropdown(false))
    useOnClickOutside(colorMenuRef, () => setColorMenu(false))

    useEffect(() => {

        if (!getTagsData){
            getCompanyFilterData()
        }
    }, [])

    const handleTagToggle = (tag, interviewID) => {
        addInterviewTag({"tag_id": tag.id, "interview_id": interviewID}, props, (bol) => {
            !bol && showAlert(true)
            bol && onHide()
            getCompanyFilterData()
        })
        setDropdown(false)
    }


    const handleTags = allTags => {
        const interviewTags = tags
        const intTagsIDs = interviewTags?.map(tag => (tag = tag.id)) || []

        const filteredTags = allTags?.filter(function (x) {
            return !intTagsIDs.includes(x.id)
        })

        return filteredTags
    }

    const toggleDeleteCompanyTag = (tag) => {
        deleteCompanyTag({tag_id: tag}, props, (bol) => {
            showAlert(true)
        })
    }

    const renderTags = tags => {
        let result = []
        if (tags && tags.length > 0) {
            tags.map(t => {
                t.name?.toLowerCase()
                    ?.includes(tag.toLowerCase()) && (
                    result.push(
                        <li key={t?.id} className="list-group-item">
                            <Row>
                                <Col lg="10"
                                     onClick={() => handleTagToggle(t, interview.id)}
                                     style={{cursor: "pointer"}}
                                >
                                    <Row>
                                        <Col lg="7">
                                            <div className="d-inline-block mr-2">{t?.name}</div>
                                        </Col>
                                        <Col lg={5}>
                                            <span>({t?.tag_interView_count ? t?.tag_interView_count : 0})</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={1}>
                <span
                    onClick={() => toggleDeleteCompanyTag(t?.id)}
                    className="badge font-size-10 ml-1 text-danger"
                    style={{cursor: "pointer"}}
                >
                  X
                </span>
                                </Col>
                            </Row>
                        </li>
                    )
                )
            })
        }
        return result
    }

    const createNewTag = () => {
        let error = false
        const errorObj = {...errorsObj}
        if (tag === "") {
            errorObj.tag = "Tag is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            "tag_data": {
                "name": tag,
                "color": color
            }
        }
        // addTag(data, props, () => showAlert(true))
        addInterviewTag({"tag_id": null, "interview_id": interview.id, ...data}, props, (bol) => {
            !bol && showAlert(true)
            bol && onHide()
        })
        setDropdown(false)
        setTag("")
    }

    const handleTagChange = value => {
        // console.log(value)
        // dispatch(listTagSearch(`?name=${value}`))
        setTag(value)
    }

    const handleSetColor = color => {
        setColor(color)
        setColorMenu(false)
    }

    return (
        <div className="new-tag-card p-3">
            {/*<span className="mb-3 mt-2 d-block">*/}
            {/*  <h5>*/}
            {/*    {interview?.Candidate?.first_name} {interview?.Candidate?.last_name}*/}
            {/*  </h5>*/}
            {/*</span>*/}
            <Alert alert={alert}
                   type={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "error"}
                   variant={(getMessage?.success || getMessage?.delete) ? "success" : getMessage?.error && "danger"}
                   msg={getMessage?.success || getMessage?.delete || getMessage?.error}
                   dismissAlert={() => showAlert(false)}/>
            {/*<h5>Add a Tag</h5>*/}
            {interview?.Tag?.length < 4 ? (
                <>
                    <Row className="mb-1">
                        <Col lg={10}>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    value={tag}
                                    onClick={() => setDropdown(true)}
                                    onChange={e => handleTagChange(e.target.value)}
                                    onKeyPress={e => {
                                        if (e.key === "Enter") {
                                            createNewTag()
                                            onHide()
                                        }
                                    }}
                                    placeholder="Search or Create a tag"
                                />
                                <span className="input-group-append bg-white">
                  {/*<span className="input-group-text bg-transparent">*/}
                                    {/*  <i className="bx bx-search-alt"></i>*/}
                                    {/*</span>*/}
                </span>
                            </div>
                            {errors.tag &&
                                <div className="animated fadeInUp text-danger fs-12 mb-1"
                                     style={{marginTop: 5}}>{errors.tag}</div>}
                            {dropdown ? (
                                <div ref={ref}>
                                    <UncontrolledDropdown>
                                        <div
                                            style={{
                                                position: "absolute",
                                                zIndex: 100,
                                                width: "230px",
                                                backgroundColor: "#FFFF",
                                                minWidth: "200px"
                                            }}
                                        >
                                            <div className="new-tag-members custom-scroll">
                                                <ul className="list-group">
                                                    {renderTags(handleTags(getTagsData?.tags))}
                                                </ul>
                                            </div>
                                        </div>
                                    </UncontrolledDropdown>
                                </div>
                            ) : (
                                ""
                            )}
                        </Col>
                        {/*<Col lg={2} style={{padding: "0px"}}>*/}
                        {/*    <div*/}
                        {/*        className="color-input"*/}
                        {/*        onClick={() => setColorMenu(true)}*/}
                        {/*        style={{*/}
                        {/*            backgroundColor: color,*/}
                        {/*            width: "30px",*/}
                        {/*            height: "30px",*/}
                        {/*            borderRadius: "100%",*/}
                        {/*            overflow: "hidden",*/}
                        {/*            cursor: "pointer"*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        {/*<Col>*/}
                        {/*    {colorMenu ? (*/}
                        {/*        <div ref={colorMenuRef}>*/}
                        {/*            <UncontrolledDropdown>*/}
                        {/*                <div*/}
                        {/*                    style={{position: "absolute", right: 0, zIndex: 100}}*/}
                        {/*                    className="p-2 bg-white rounded"*/}
                        {/*                >*/}
                        {/*                    <Row className="mb-2">*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#FFA800")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#FFA800",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#FF475B")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#FF475B",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#551ECB")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#551ECB",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#009FFA")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#009FFA",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                    </Row>*/}
                        {/*                    <Row>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#00BA52")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#00BA52",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#CF6BE8")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#CF6BE8",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#962010")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#962010",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                        <Col>*/}
                        {/*                            <div*/}
                        {/*                                className="color-input"*/}
                        {/*                                onClick={() => handleSetColor("#FF6B00")}*/}
                        {/*                                style={{*/}
                        {/*                                    backgroundColor: "#FF6B00",*/}
                        {/*                                    width: "30px",*/}
                        {/*                                    height: "30px",*/}
                        {/*                                    borderRadius: "100%",*/}
                        {/*                                    overflow: "hidden",*/}
                        {/*                                    cursor: "pointer"*/}
                        {/*                                }}*/}
                        {/*                            />*/}
                        {/*                        </Col>*/}
                        {/*                    </Row>*/}
                        {/*                </div>*/}
                        {/*            </UncontrolledDropdown>*/}
                        {/*        </div>*/}
                        {/*    ) : (*/}
                        {/*        ""*/}
                        {/*    )}*/}
                        {/*</Col>*/}
                        <i
                            style={{cursor: "pointer"}}
                            onClick={() => props.onHide()}
                            className="fa fa-close font-size-18 text-danger"
                        />
                    </Row>
                </>
            ) : (
                ""
            )}


        </div>
    )
}

const mapStateToProps = state => {
    return {
        getTagsData: getDataForTagsModal(state),
        getMessage: getMeiaAddedMessage(state),
        getMemberData: getDataForMembersModal(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyFilterData: (data) => dispatch(getCompanyCandidateFilterDataAction(data)),
        addTag: (data, props, cb) => dispatch(addCompanyTagAction(data, props.history, cb)),
        addInterviewTag: (data, props, cb) => dispatch(addCompanyInterviewTagAction(data, props.history, cb)),
        addTeamMemberAction: (data, props, cb) => dispatch(addCompanyCandidateTeamMemberAction(data, props.history, cb)),
        deleteCompanyTag: (data, props, cb) => dispatch(deleteCompanyTagAction(data, props.history, cb))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagsInput));


function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return
                }

                handler(event)
            }

            document.addEventListener("mousedown", listener)
            document.addEventListener("touchstart", listener)

            return () => {
                document.removeEventListener("mousedown", listener)
                document.removeEventListener("touchstart", listener)
            }
        },
        [ref, handler]
    )
}