import PropTypes from "prop-types";
import React, {useState} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";

import VideoThumbnail from "react-video-thumbnail";

import videoNotAvailable from "./../../../images/video-not-available.svg";

const VideoResume = ({evaluation, setProfileModal, loading}) => {
    // console.log(evaluation);
    return (
        <div className="video-resume">
            <Card className="border border-primary">
                <div className="card-header">
                    <h5 className="card-title">Video Resume</h5>
                </div>
                <CardBody>
                    {loading ? (
                        <Row className="align-items-center justify-content-center">
                            <Col lg={6}>
                                <Spinner
                                    size="sm"
                                    className="m-auto text-center"
                                    color="success"
                                />
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row className="align-items-center">
                                <Col lg={5}>
                                    {evaluation?.Candidate?.CandidateProfile.video_resume_thumbnail ?
                                        <div className="bg-secondary-light"
                                             style={{backgroundColor: "#f8f9fa"}}>
                                            <img
                                                src={evaluation?.Candidate?.CandidateProfile.video_resume_thumbnail}
                                                alt="video resume thumbnail"
                                                className="img-fluid"
                                                height="90px"
                                                width="100px"
                                                style={{objectFit: "cover", height: "90px", width: "100px"}}
                                            />
                                        </div> :
                                        evaluation?.Candidate?.CandidateProfile.video_resume ? (
                                            <div style={{height: "90px", overflow: "hidden"}}>
                                                <VideoThumbnail
                                                    videoUrl={evaluation?.Candidate?.CandidateProfile?.video_resume}
                                                    height={90}
                                                    width={90}
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                            // <div className="bg-secondary-light p-3 rounded"
                                            //      style={{backgroundColor: "#f8f9fa"}}>
                                            //     <img
                                            //         src={videoNotAvailable}
                                            //         className="img-fluid"
                                            //         width="100%"
                                            //     />
                                            // </div>
                                        )}
                                </Col>
                                <Col>


                                    {evaluation?.Candidate?.CandidateProfile?.video_resume && (
                                        <>
                                            <span className="mb-2 d-block">Video Resume
                                                <br/>
90 Seconds Video Resume.</span>
                                            <button
                                                // disabled={
                                                //   evaluation?.Candidate?.video_resume ? false : true
                                                // }
                                                onClick={() => setProfileModal(true)}
                                                className="btn btn-primary"
                                            >
                                                Watch <i className="mdi mdi-play"></i>
                                            </button>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default VideoResume;
