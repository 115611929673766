export const isAuthenticated = (state) => {
    if (state.auth.auth.token) return true
    return false
}

export const isVerified = (state) => {
    if (state.auth.auth.verified) return true
    return false
}

export const hasCompanyProfile = (state) => {
    if (state.auth.auth.company_profile) return true
    return false
}

export const getUserProfile = (state) => {
    if (state.auth.profile) return state.auth.profile
    return false
}

export const getProfileMessage = (state) => {
    if (state.company) return {success: state.auth.updateMessage, error: state.auth.errorMessage}
    return false
}

export const getUserProfileLoading = (state) => {
    if (state.auth.userProfileLoading) return state.auth.userProfileLoading
    return false
}

export const getUserMessage = (state) => {
    if (state.auth.successMessage) return state.auth.successMessage
    return false
}

export const getUserPermissions = (state) => {
    if (state.auth.permissions) return state.auth.permissions
    return false
}
export const getErrorMessage = (state) => {
    if (state.auth.errorMessage) return state.auth.errorMessage
    return false
}
export const getAuthSignUpMessage = (state) => {
    if (state?.auth?.auth?.message) return state.auth.auth.message
    return false
}